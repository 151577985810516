import React from "react";
import Hero from "../sections/Hero";

function HomePage() {
  return (
    <div>
      <Hero />
    </div>
  )
}

export default HomePage;
