import React from "react";
import AllFilms from "../filmReviews/AllFilms";

function AllFilmsPage() {
  return (
    <div>
      <AllFilms />
    </div>
  )
}

export default AllFilmsPage;
