import { Flex } from '@chakra-ui/react';
import React from 'react';

function Pagination({ onPageChange, pagy }) {
  
  return (
    <Flex justifyContent={'space-between'}>
      <button onClick={() => onPageChange(pagy.prev_url)}>
        <svg width="46" height="42" viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M43.095 23.7338C44.6048 23.7338 45.8287 22.5098 45.8287 21C45.8287 19.4902 44.6048 18.2662 43.095 18.2662V23.7338ZM1.06692 19.0669C-0.000676025 20.1345 -0.000676025 21.8655 1.06692 22.9331L18.4644 40.3305C19.532 41.3981 21.2629 41.3981 22.3305 40.3305C23.3981 39.2629 23.3981 37.532 22.3305 36.4644L6.86608 21L22.3305 5.53557C23.3981 4.46798 23.3981 2.73706 22.3305 1.66947C21.2629 0.60187 19.532 0.60187 18.4644 1.66947L1.06692 19.0669ZM43.095 18.2662L2.99997 18.2662V23.7338L43.095 23.7338V18.2662Z" fill="#DC6738"/>
        </svg>
      </button>
      <button onClick={() => onPageChange(pagy.next_url)}>
        <svg width="46" height="42" viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.99416 18.3519C1.48436 18.3551 0.263031 19.5817 0.266256 21.0915C0.269481 22.6013 1.49603 23.8226 3.00584 23.8194L2.99416 18.3519ZM45.0321 22.9289C46.0974 21.859 46.0937 20.1281 45.0238 19.0628L27.5892 1.70253C26.5194 0.63722 24.7884 0.640917 23.7231 1.71079C22.6578 2.78066 22.6615 4.51158 23.7314 5.57689L39.2288 21.0082L23.7974 36.5057C22.7321 37.5755 22.7358 39.3065 23.8057 40.3718C24.8756 41.4371 26.6065 41.4334 27.6718 40.3635L45.0321 22.9289ZM3.00584 23.8194L43.1007 23.7337L43.0891 18.2662L2.99416 18.3519L3.00584 23.8194Z" fill="#DC6738"/>
        </svg>
      </button>
    </Flex>
  );
};

export default Pagination;
